import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useAuth } from '../../contexts/AuthContext';
import { carnet } from '../../services/api';
import { Mail, Phone, MapPin } from 'lucide-react';
import FooterMenu from '../FooterMenu';
import Header from '../Header';

const CarnetView = () => {
  const { user } = useAuth();
  const [carnetData, setCarnetData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCarnetData = async () => {
      try {
        const response = await carnet.getCarnet();
        setCarnetData(response.data);
      } catch (error) {
        console.error('Error al obtener los datos del carnet:', error);
        setError('Error al cargar los datos del carnet');
      }
    };

    fetchCarnetData();
  }, []);

  if (error) {
    return <div className="flex justify-center items-center min-h-screen text-red-500">{error}</div>;
  }

  if (!carnetData) {
    return <div className="flex justify-center items-center min-h-screen">Cargando datos del carnet...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-orange-300 to-pink-300">
      <Header />
      <div className="flex-grow flex flex-col gap-4 justify-center items-center px-4 py-8">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm">
          <div className="mb-4">
            <QRCodeSVG value={JSON.stringify(carnetData)} size={250} className="mx-auto" />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm">
          <div className="text-center mb-4 flex items-center justify-center">
            <img
              src="/logo512.png"
              alt="Foto de perfil"
              className="w-9 h-9 rounded-full mx-auto mb-2"
            />
            <div>
              <h2 className="text-xl font-semibold text-gray-800">{user?.first_name} {user?.last_name}</h2>
              <p className="text-sm text-gray-600">{carnetData.role || 'Software Tester'}</p>
            </div>
          </div>
          <div className="bg-green-100 rounded-md p-2 mb-4">
            <p className="text-sm text-green-800 font-semibold text-center">
              Carnet Vigente
              <br />
              Vence {carnetData.expiration_date || '12/90'}
            </p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center text-gray-700">
              <Mail size={18} className="mr-2" />
              <p className="text-sm">Mail</p>
            </div>
            <div className="flex items-center text-gray-700">
              <Phone size={18} className="mr-2" />
              <p className="text-sm">Phone</p>
            </div>
            <div className="flex items-center text-gray-700">
              <MapPin size={18} className="mr-2" />
              <p className="text-sm">Dirección</p>
            </div>
          </div>
        </div>
      </div>
      <FooterMenu />
    </div>
  );
};

export default CarnetView;
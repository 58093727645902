import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, Home, CreditCard, Settings, Users, Palette, CheckSquare, User } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { user } = useAuth();

  const toggleMenu = () => setIsOpen(!isOpen);

  const menuItems = [   
    { path: '/carnet', name: 'Carnet', icon: CreditCard },
    { path: '/validate', name: 'Validar Carnet', icon: CheckSquare },
  ];

  const adminItems = [
    { path: '/dashboard', name: 'Dashboard', icon: Home },
    { path: '/admin/users', name: 'Gestión de Usuarios', icon: Users },
    { path: '/admin/carnet-designer', name: 'Diseñador de Carnet', icon: Palette },
  ];

  const getFullName = () => {
    if (user?.first_name && user?.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }
    return user?.username || 'Usuario';
  };

  const getRoleLabel = () => {
    if (user?.role === 'admin') return 'Administrador';
    if (user?.is_premium) return 'Usuario Premium';
    return 'Usuario';
  };

  return (
    <header className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <img src="/logo512.png" alt="Company Logo" className="w-12 h-12 mr-4" />
           
          </div>
          <button onClick={toggleMenu} className="text-gray-500 hover:text-gray-600 focus:outline-none">
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>
      
      {/* Menú lateral */}
      <nav className={`fixed top-0 right-0 bottom-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} z-50 flex flex-col`}>
        <div className="flex justify-end p-4">
          <button onClick={toggleMenu} className="text-gray-500 hover:text-gray-600 focus:outline-none">
            <X size={24} />
          </button>
        </div>
        <ul className="flex-grow mt-8">
          {menuItems.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                className={`flex items-center px-6 py-3 text-gray-700 hover:bg-gray-100 ${location.pathname === item.path ? 'bg-red-100 text-red-600' : ''}`}
                onClick={toggleMenu}
              >
                <item.icon className="mr-3" size={20} />
                {item.name}
              </Link>
            </li>
          ))}
          {user?.role === 'admin' && adminItems.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                className={`flex items-center px-6 py-3 text-gray-700 hover:bg-gray-100 ${location.pathname === item.path ? 'bg-red-100 text-red-600' : ''}`}
                onClick={toggleMenu}
              >
                <item.icon className="mr-3" size={20} />
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* Información del usuario al final del menú lateral */}
        <div className="p-4 border-t border-gray-200">
          <div className="flex items-center">
            {user?.profileImage ? (
              <img src={user.profileImage} alt="User Avatar" className="w-12 h-12 rounded-full mr-4" />
            ) : (
              <User size={48} className="text-gray-400 mr-4" />
            )}
            <div>
              <h2 className="text-lg font-semibold">{getFullName()}</h2>
              <p className="text-sm text-gray-600">{user?.email || 'usuario@ejemplo.com'}</p>
              <p className="text-xs text-gray-500">{getRoleLabel()}</p>
            </div>
          </div>
        </div>
      </nav>
      
      {/* Overlay para cerrar el menú al hacer clic fuera */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleMenu}
        ></div>
      )}
    </header>
  );
};

export default Header;
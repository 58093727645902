import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import Header from '../Header';
import FooterMenu from '../FooterMenu';

const Dashboard = () => {
  const { user } = useAuth();
  const [carnet, setCarnet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCarnet = async () => {
      console.log('Fetching carnet...'); // Log al inicio de la función
      console.log('Current user:', user); // Log del usuario actual
      try {
        const token = localStorage.getItem('token'); // Obtener el token del localStorage
        console.log('Token:', token); // Log del token (ten cuidado de no exponer tokens completos en producción)
        
        const response = await axios.get('/api/carnets/detail/', {
          headers: { Authorization: `Bearer ${token}` } // Incluir el token en la solicitud
        });
        console.log('API response:', response.data);
        setCarnet(response.data);
      } catch (err) {
        console.error('Error fetching carnet:', err);
        setError('Error al cargar el carnet: ' + (err.response?.data?.message || err.message));
      } finally {
        setLoading(false);
      }
    };

    if (user) { // Solo intentar cargar el carnet si hay un usuario autenticado
      fetchCarnet();
    } else {
      console.log('No user authenticated, skipping carnet fetch');
      setLoading(false);
    }
  }, [user]); // Dependencia añadida para que se ejecute cuando cambie el usuario

  if (loading) {
    return <div className="text-center mt-8">Cargando...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold mb-4">Bienvenido, {user?.first_name || 'Usuario'}</h1>
       
        {error && <div className="text-red-500 mb-4">{error}</div>}
       
        {user ? (
          carnet ? (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Tu Carnet Digital
                </h3>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Tipo de membresía
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {carnet.membership_type || 'No disponible'}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Código QR
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {carnet.qr_code ? (
                        <img src={carnet.qr_code} alt="Código QR" className="w-32 h-32" />
                      ) : (
                        'Código QR no disponible'
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          ) : (
            <div className="text-center mt-8">No se pudo cargar la información del carnet.</div>
          )
        ) : (
          <div className="text-center mt-8">Por favor, inicia sesión para ver tu carnet.</div>
        )}
      </div>
      <FooterMenu />
    </div>
  );
};

export default Dashboard;
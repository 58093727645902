import React, { useState, useEffect, useRef } from 'react';
import api from '../services/api';
import Header from './Header';
import FooterMenu from './FooterMenu';

const DirectorioEmpresas = () => {
  const [empresas, setEmpresas] = useState([]);
  const [filteredEmpresas, setFilteredEmpresas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState('');
  const [isCategoriaMenuOpen, setIsCategoriaMenuOpen] = useState(false);
  const categoriaMenuRef = useRef(null);

  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const response = await api.get('/directorio-empresas/');
        if (response.data && response.data.empresas) {
          const empresasResponse = await api.get(response.data.empresas);
          if (Array.isArray(empresasResponse.data)) {
            setEmpresas(empresasResponse.data);
            setFilteredEmpresas(empresasResponse.data);
            const uniqueCategorias = [...new Set(empresasResponse.data.map(empresa => empresa.categoria))];
            setCategorias(uniqueCategorias);
          } else {
            setError('Los datos de las empresas no tienen el formato esperado.');
          }
        } else {
          setError('No se pudo obtener la lista de empresas.');
        }
      } catch (err) {
        console.error('Error al obtener empresas:', err);
        setError('Hubo un problema al cargar las empresas. Por favor, intenta de nuevo más tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchEmpresas();
  }, []);

  useEffect(() => {
    const filteredResults = empresas.filter(empresa =>
      empresa.nombre.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategoria === '' || empresa.categoria === selectedCategoria)
    );

    setFilteredEmpresas(filteredResults);
  }, [searchTerm, selectedCategoria, empresas]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (categoriaMenuRef.current && !categoriaMenuRef.current.contains(event.target)) {
        setIsCategoriaMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleCategoriaMenu = () => {
    setIsCategoriaMenuOpen(!isCategoriaMenuOpen);
  };

  const handleCategoriaSelect = (categoria) => {
    setSelectedCategoria(categoria);
    setIsCategoriaMenuOpen(false);
  };

  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="mb-4 flex items-center gap-2">
          <input
            type="text"
            placeholder="Buscar empresa..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow p-2 border rounded"
          />
          <div className="relative" ref={categoriaMenuRef}>
            <button
              onClick={toggleCategoriaMenu}
              className="p-2 bg-gray-200 rounded"
              aria-label="Filtrar por categoría"
            >
              {/* Icono de categoría (puedes reemplazarlo con un SVG más elaborado si lo deseas) */}
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
            {isCategoriaMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                <div className="py-1">
                  <button
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => handleCategoriaSelect('')}
                  >
                    Todas las categorías
                  </button>
                  {categorias.map((categoria) => (
                    <button
                      key={categoria}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => handleCategoriaSelect(categoria)}
                    >
                      {categoria}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        
        {loading && <div>Cargando...</div>}
        {error && <div>Error: {error}</div>}
        {!loading && !error && (
          filteredEmpresas.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredEmpresas.map((empresa) => (
                <div key={empresa.id} className="border p-4 rounded-lg shadow">
                  {empresa.logo_url && (
                    <img 
                      src={empresa.logo_url} 
                      alt={`Logo de ${empresa.nombre}`} 
                      className="w-full h-32 object-contain mb-2" 
                      onError={(e) => {
                        e.target.src = 'https://via.placeholder.com/150?text=Logo+no+disponible';
                      }}
                    />
                  )}
                  <h2 className="text-xl font-semibold">{empresa.nombre}</h2>
                  <p className="text-sm text-gray-600">{empresa.categoria}</p>
                  <p className="text-sm text-gray-600">{empresa.direccion}</p>
                  <p className="mt-2">{empresa.descripcion}</p>
                  <div className="mt-2">
                    <p>Teléfono: {empresa.telefono}</p>
                    <p>Email: {empresa.correo}</p>
                    <p>Web: <a href={empresa.sitio_web} target="_blank" rel="noopener noreferrer">{empresa.sitio_web}</a></p>
                  </div>
                  {empresa.imagenes && empresa.imagenes.length > 0 && (
                    <div className="mt-4">
                      <h3 className="font-semibold">Imágenes:</h3>
                      <div className="grid grid-cols-3 gap-2 mt-2">
                        {empresa.imagenes.map((imagen, index) => (
                          <img 
                            key={imagen.id || index} 
                            src={imagen.imagen || imagen} 
                            alt={`Imagen ${index + 1} de ${empresa.nombre}`} 
                            className="w-full h-20 object-cover rounded"
                            onError={(e) => {
                              e.target.src = 'https://via.placeholder.com/150?text=Imagen+no+disponible';
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p>No hay empresas para mostrar.</p>
          )
        )}
      </div>
      <FooterMenu />
    </>
  );
};

export default DirectorioEmpresas;
import React from 'react';
import { Link } from 'react-router-dom';
import { MessageSquare, QrCode, Building2 } from 'lucide-react';

const FooterMenu = () => {
  return (
    <footer className="fixed bottom-0 w-full h-20 bg-[#ffffff] flex justify-center items-center shadow-[0_-1px_2px_0_rgba(60,64,67,.3),0_-2px_6px_2px_rgba(60,64,67,.15)]">
      <section className="w-[90%] flex items-center justify-around">
        <Link to="/chatbot" className="flex flex-col justify-center items-center w-1/3 no-underline text-[#2C2B4B]">
          <MessageSquare size={24} />
          <span className="text-sm mt-1">Chatbot</span>
        </Link>

        <Link to="/carnet" className="flex flex-col justify-center items-center w-1/3 no-underline text-[#2C2B4B]">
          <div className="flex w-[70px] h-[70px] justify-center items-center bg-[#fee2e2] rounded-full mt-[-50px]">
            <div className="flex items-center justify-center w-[55px] h-[55px] bg-[#dc2626] rounded-full shadow-[inset_0_1px_2px_0_rgba(60,64,67,.3),inset_0_2px_6px_2px_rgba(60,64,67,.15)]">
              <QrCode size={28} color="white" />
            </div>
          </div>
          <span className="text-sm mt-1">Carnet Digital</span>
        </Link>

        <Link to="/directorio-empresas" className="flex flex-col justify-center items-center w-1/3 no-underline text-[#2C2B4B]">
          <Building2 size={24} />
          <span className="text-sm mt-1">Directorio</span>
        </Link>
      </section>
    </footer>
  );
};

export default FooterMenu;